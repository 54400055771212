import {UserMunicipality} from '@shared/models/user-municipality.model';

export const SandnesMunicipality: UserMunicipality = {
  name: 'sandnes',
  slogan: {
    value: 'Organisasjoner som jobber med inkludering av utsatte grupper i Sandnes'
  },
  data: {},
  bannerURL: 'https://ucarecdn.com/3aa6954b-8daf-4487-8b07-e7e97341fb12/Sandnes__Norway_in_2015_rz.jpg',
  bannerType: 'image',
  hasLeisureCard: false,
  userCanClaimLeisureCard: false,
  canClaimInOrganization: false,
  hasSubsidiaryOrg: false,
  sunset: false,
  hasPayment: false,
  hasTickets: false,
  hasEquipment: false,
}
